<!--
 * @Author: 赵伟
 * @Date: 2020-08-08 14:28:25
 * @LastEditors: 赵伟
 * @LastEditTime: 2021-12-01 14:58:25
-->
<template lang="pug">
.login-container.flex.flex-middle.flex-center
  .w-586.h-384
    img.img-contain(src="https://static.fostars.com/ins/HZB001/img/f6b87ec8dc934bbcaf1e39818e9b1eb7_0.png")

  .ml-50.w-360.h-384.r-container
    .h-30.text-center
      //img.img-contain(src="https://static.fostars.com/oms/20211201145802.png")
      img.img-contain(src="https://static.fostars.com/ins/HZB001/img/hzbIcon.jpg")
      span.fs-36.color-28781.ml-12.fw-bold 沪智保管理系统
    el-form.mt-28(
      ref="loginForm",
      :rules="loginRules",
      :model="form",
      @submit.native.prevent,
      @keyup.enter.native="fnClickLogin"
    )
      el-form-item.pst-rlt(prop="code")
        .input-prefix-icon(style="z-index: 5;")
          icon(icon="zhanghao" size="18" color="#666")
        el-input(
          v-model.trim="form.code",
          placeholder="请输入登录账号",
          clearable,
        )
      el-form-item.pst-rlt(prop="password")
        .input-prefix-icon(style="z-index: 5;")
          icon(icon="mima" size="20" color="#666666")
        el-input(
          v-model="form.password",
          type="password",
          placeholder="请输入登录密码"
        )
      .flex.flex-middle
        icon(:icon="oRememberPwd.icon" :color="oRememberPwd.color" size="20" @click="fnClickToggle")
        span.fs-14.color-2878.ml-5 记住密码
      .mt-40.text-center
        xh-btn.w-360.h-50.lh-0.theme-bg-color(
          size="default"
          :isBlock="true"
          type="primary",
          :loading="loading",
          @click="fnClickLogin",
        ) 登录

</template>
<script>
import { mapState } from "vuex";
import { login } from "@/api";
export default {
  layout: "empty",
  head() {
    return {
      title: "沪智保管理系统",
    };
  },
  data() {
    const validateUserName = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        const tip = "请输入登录账号";
        callback(new Error(tip));
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        callback(new Error("请输入登录密码"));
      }
    };
    return {
      form: {
        code: "",
        password: ""
      },
      bVaildMobile: false,
      loginRules: {
        code: [{ trigger: "blur", validator: validateUserName }],
        password: [{ trigger: "blur", validator: validatePassword }],
      },
      loading: false,
      oRememberPwd: {
        bFlag: false, // - 记住密码flag
        color: '', // - 颜色
        icon: '' // - icon
      }
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.user.permission.menuList.children
    }),
    firstNav() {
      const firstNav = this.menuList[0]?.children?.[0]?.data?.accessUrl;
      if (!firstNav) {
        return this.menuList[0]?.children?.[0]?.children[0]?.data?.accessUrl;
      }
      return firstNav;
    },
  },
  watch: {
    "oRememberPwd.bFlag": {
      handler(val){
        if(val) {
          this.oRememberPwd.icon = 'xuanzhong';
          this.oRememberPwd.color = '#2D6CFF';
        } else {
          this.oRememberPwd.icon = 'yuanquan';
          this.oRememberPwd.color = '#E6E6E6';
        }
      },
      immediate: true
    }
  },
  mounted() {
    const rememberPwdInfo = this.$cookies.get('$nuxt_remember_pwd') || '';
    if(rememberPwdInfo){
      this.form = {
        userName: JSON.parse(rememberPwdInfo).userName,
        password: JSON.parse(rememberPwdInfo).password
      }
      this.oRememberPwd.bFlag = true;
    }
  },
  methods: {
    // - 登录
    fnClickLogin() {
      this.$refs.loginForm.validate(async (valide) => {
        if (valide) {
          this.loading = true;
          const [err, data] = await login({
            data: {
              ...this.form
            },
          });
          this.loading = false;
          if (err) return [err, data];
          // - 获取user
          this.$store.dispatch("user/fetchUserInfo", { force: true });
          // - 获取菜单
          await this.$store.dispatch("user/fetchPermission", { force: true });
          // - 获取菜单的第一个index
          this.$store.commit("user/UPDATE_MENU_ACTIVE_TAB_INDEX", 0);
          // - 记住密码
          this.$store.commit('user/UPDATE_REMEMBER_PWD', this.oRememberPwd.bFlag ? this.form : "");
          // - 跳转url
          // this.$nextTick(() => {
          //   this.$router.replace('/');
          // });
          this.$router.push('/')
        } else {
          console.log("校验错误");
        }
      });
    },
    // - 记住密码
    fnClickToggle(){
      this.oRememberPwd.bFlag = !this.oRememberPwd.bFlag;
    }
  },
};
</script>

<style lang="scss" scoped>
  .login-container {
    height: 100vh;
    overflow: hidden;
    background-color: #fff;
  }
  .color-28781{
    color: #2E6BFF;
  }
  .input-prefix-icon {
    position: absolute;
    top: 1px;
    left: 10px;
    &::after {
      position: absolute;
      right: -11px;
      top: 11px;
      width: 1px;
      height: 15px;
      background: #e6ecf2;
      content: "";
    }
  }
  ::v-deep .el-form-item__error {
    padding-left: 52px;
  }

  ::v-deep .el-input__inner {
    text-indent: 20px;
    border: 0;
    border-radius: 1px;
    border: 1px solid #e6ecf2 !important;
    border-bottom: 0;
    border-radius: 3px;
    background: #fff !important;
    padding-left: 30px;
    ::v-deep &:focus {
      background: #fff;
    }
  }
  </style>

<style>
.w-586{width:586px;}.h-384{height:384px;}.ml-50{margin-left:50px;}.w-360{width:360px;}.h-30{height:30px;}.fs-36{font-size:36px;}.ml-12{margin-left:12px;}.mt-28{margin-top:28px;}.fs-14{font-size:14px;}.ml-5{margin-left:5px;}.mt-40{margin-top:40px;}.h-50{height:50px;}.lh-0{line-height:0px;}</style>
